script<template>
  <div v-if="state.isInitialized" class="order-form-section">
    <div class="item-row-header-ps">
      <span>Point Of Sale</span>
    </div>
    <div>
      <div class="d-flex">
        <div v-if="showPointOfSaleLable">
          <label name="lable-search-pos-code" class="form-label">Code:</label>
        </div>
        <point-of-sale-picker
          v-if="!posCode"
          @pos-selected="saveAndEmitPosIfValid"
        ></point-of-sale-picker>

        <div v-if="posCode" class="d-flex">
          <div>
            {{ posCode.toUpperCase() }}
            <span v-if="posImported" title="Added By Admin">
              <i class="fas fa-globe text-success" />
            </span>
            <span v-else title="Added By you">
              <i class="fas fa-location-dot text-primary" />
            </span>
          </div>
          <div title="Change Point Of Sale">
            <button
              class="btn-no-style"
              tabindex="0"
              @click="clearAndEmitPointOfSale"
              @touchend="clearAndEmitPointOfSale"
              @keydown.enter="clearAndEmitPointOfSale"
            >
              <i class="fa fa-folder-open" title="Change Point Of Sale" />
            </button>
          </div>
        </div>
      </div>
      <div class="separator" />
      <div v-if="posCode" class="d-flex">
        <div v-if="showCustomerNameLable">
          <label
            name="customer-name-label"
            class="form-label"
            title="Customer Name"
            >Name:</label
          >
        </div>
        <div v-if="state.isEditingCustomerName" class="input-group">
          <input
            id="customerName"
            v-model="state.localCustomerName"
            name="customer-name-input"
            ref="customerNameRef"
            class="form-control"
            autocomplete="off"
            placeholder="Enter customer name"
            title="Enter customer name"
            @keyup.enter="saveCustomerName"
            @blur="handleNoCustomerNameUpdate"
          />
          <button
            v-if="isValidCustomerName"
            class="btn btn-success"
            title="Confirm Customer Name"
            @click="saveCustomerName"
          >
            <i class="fas fa-square-check" />
          </button>
        </div>
        <div v-else class="d-flex">
          <div>
            {{ formatCustomerName(customerName) }}
          </div>

          <div v-if="!posImported">
            <button
              class="btn-no-style"
              tabindex="0"
              title="Edit Customer Name"
              @click="promptCustomerNameEdit"
              @keydown.enter="promptCustomerNameEdit"
            >
              <i class="fas fa-pen-to-square" title="Edit Customer Name" />
            </button>
          </div>
        </div>
      </div>
      <div class="separator" />
      <div v-if="hasPOS" class="d-flex">
        <div>
          <label name="customer-name-label" class="form-label">Orders:</label>
        </div>
        <div class="d-flex">
          <div>
            <button
              class="btn-no-style"
              tabindex="0"
              title="Show Order History"
              @click="showOrderHistory"
              @keydown.enter="store.switchToPreviousPointOfSale"
            >
              History
              <i class="fas fa-history" title="Show Order History" />
            </button>
          </div>
        </div>
      </div>
      <div class="separator" />
      <div v-if="hasPOS" class="d-flex">
        <div>
          <label name="customer-name-label" class="form-label">Store:</label>
        </div>

        <div class="d-flex">
          <div>
            {{ storeName }}
          </div>
          <div class="ml-10">
            <ImageComponent
              :baseUrl="storeFlag"
              :width="Number(16)"
              :tab-index="0"
              :title="storeName + ' branch flag'"
              :allow-pop-up-on-click="false"
            ></ImageComponent>
          </div>
        </div>
      </div>
      <div class="separator" />
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  reactive,
  computed,
  onMounted,
  nextTick,
  inject,
  watch,
} from "vue";
import { PointsOfSaleRepo } from "@/services/DbAccess/PointOfSaleRepo";
import PointOfSalePicker from "@/components/PointOfSale/PointOfSalePicker.vue";
import { WorkingItemRepo } from "@/services/DbAccess/WorkingItemRepo";
import { infoToast, errorToast } from "@/utils/toast-utils.js";
import ImageComponent from "@/components/General/ImageComponent.vue";
import { useOrderFormStore } from "@/stores/orderFormStore";
import { formatCustomerName, validInput } from "@/utils/utils";
import { storeToRefs } from "pinia";
const EventBus = inject("EventBus");
const isMobile = inject("isMobile");
const store = useOrderFormStore();
const {
  userStoreId,
  storeName,
  storeFlag,
  posCode,
  hasPOS,
  customerName,
  posImported,
} = storeToRefs(store);

const customerNameRef = ref(null);
const state = reactive({
  isInitialized: false,
  locaLPOSCode: null,
  localCustomerName: null,
  isEditingCustomerName: false,
  customerNameTimeout: null,
});

const isValidCustomerName = computed(() => validInput(state.localCustomerName));
const showPointOfSaleLable = computed(() => {
  return !isMobile.value || posCode.value;
});
const showCustomerNameLable = computed(() => {
  return !isMobile.value || !state.isEditingCustomerName;
});

const saveCustomerName = async () => {
  if (isValidCustomerName.value && !posImported.value) {
    await saveAndEmitPosIfValid(state.locaLPOSCode, state.localCustomerName);
    return;
  }
  if (posImported.value) {
    if (validInput(customerName.value)) {
      await saveAndEmitPosIfValid(state.locaLPOSCode, customerName.value);
      return;
    } else {
      if (validInput(state.localCustomerName)) {
        await saveAndEmitPosIfValid(
          state.locaLPOSCode,
          state.localCustomerName
        );
        return;
      }
    }
  }
};

const showOrderHistory = () => {
  EventBus.$emit("show-order-history-modal");
};

const promptCustomerNameEdit = async () => {
  if (posImported.value) {
    return;
  }
  state.isEditingCustomerName = true;
  await nextTick();
  if (customerNameRef.value) {
    customerNameRef.value.focus();
  }
};

const handleNoCustomerNameUpdate = async () => {
  if (state.customerNameTimeout) {
    clearTimeout(state.customerNameTimeout);
  }
  await nextTick();
  state.customerNameTimeout = setTimeout(async () => {
    await store.getSelectedPointOfSale();
  }, 1500);
};

const saveAndEmitPosIfValid = async (
  codeIn,
  customerNameIn,
  switchSelected = false
) => {
  try {
    if (!validInput(codeIn)) {
      if (switchSelected) {
        errorToast(
          `Point of sale ${codeIn} was not found or it was deleted. Please select a valid point of sale.`
        );
        return;
      }
      await clearAndEmitPointOfSale();
      return;
    }
    if (validInput(customerNameIn)) {
      state.isEditingCustomerName = false;
    } else {
      state.isEditingCustomerName = true;
      setEditingCustomerNameFocus();
    }

    const posData = {
      code: codeIn.toUpperCase(),
      customerName: formatCustomerName(customerNameIn),
      storeId: userStoreId.value,
    };

    const resultSaving = await store.savePointOfSale(posData);
    if (!resultSaving) {
      if (switchSelected) {
        errorToast(
          `Point of sale ${codeIn} was not found or it was deleted. Please select a valid point of sale.`
        );
        return;
      }
      errorToast(
        "Failed to save the selected point of sale. Please try again."
      );
      await clearAndEmitPointOfSale();
      return;
    } else {
      if (switchSelected) {
        infoToast(
          `You've switched to the '${state.locaLPOSCode}' point of sale.`
        );
      }
    }
  } catch (error) {
    console.error("Error while saving and emitting point of sale", error);
  }
};

const clearAndEmitPointOfSale = async () => {
  state.locaLPOSCode = null;
  state.localCustomerName = null;
  state.searchTerm = null;
  state.isEditingCustomerName = false;

  await store.clearPointOfSale();
  await WorkingItemRepo.clearExpiredWorkingItems();
  await nextTick();

  if (state.searchPosInput) {
    state.searchPosInput.focus();
  }
};

const switchSelectedPos = async (code) => {
  const resultPointOfsale = await PointsOfSaleRepo.find(
    code,
    userStoreId.value
  );
  if (resultPointOfsale && resultPointOfsale?.code) {
    return await saveAndEmitPosIfValid(
      resultPointOfsale.code,
      resultPointOfsale.customerName,
      true
    );
  }
  var historyPointOfSale = await PointsOfSaleRepo.findFromHistory(
    code,
    userStoreId.value
  );
  if (historyPointOfSale && historyPointOfSale?.code) {
    return await saveAndEmitPosIfValid(
      historyPointOfSale.code,
      historyPointOfSale.customerName,
      true
    );
  }
  errorToast(
    `Point of sale ${code} was not found or it was deleted. Please select a valid point of sale.`
  );
};

const pointOfSalesImportComplete = async () => {
  try {
    await store.getSelectedPointOfSale(true);
  } catch (error) {
    console.error(
      "Error while updating selected pos and customer name after import",
      error
    );
  }
};

const setEditingCustomerNameFocus = () => {
  if (customerNameRef.value) {
    customerNameRef.value.focus();
  } else {
    setTimeout(async () => {
      await nextTick();
      if (customerNameRef.value) {
        customerNameRef.value.focus();
      }
    }, 150);
  }
};

onMounted(async () => {
  await store.getSelectedPointOfSale();
  state.isInitialized = true;
  await nextTick();
  if (!validInput(customerName.value)) {
    state.isEditingCustomerName = true;
    setEditingCustomerNameFocus();
  }
  EventBus.$on("switch-selected-pos", switchSelectedPos);
  EventBus.$on("points-of-sale-imported", pointOfSalesImportComplete);
});

watch(
  () => posCode.value,
  () => {
    state.locaLPOSCode = posCode.value;
  }
);
watch(
  () => customerName.value,
  async () => {
    await nextTick();
    state.localCustomerName = customerName.value;
    if (!validInput(customerName.value)) {
      state.isEditingCustomerName = true;
      setEditingCustomerNameFocus();
    }
  }
);
</script>