<template>
  <div :class="modalClasses" tabindex="-1">
    <div
      class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ modalTitle }}</h5>
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          <p class="text-muted">
            {{ modeDescription }}
          </p>
          <div class="search-container mb-3">
            <input
              v-model="searchQuery"
              @input="debounceSearch"
              type="text"
              class="form-control"
              placeholder="Search orders..."
            />
          </div>
          <div class="item-list">
            <div class="item-header">
              <div
                v-for="header in currentOrderHeaders"
                :key="header.key"
                class="header-item"
                :class="{ sortable: isSortable(header) }"
                @click="sortOrders(header.key)"
              >
                {{ header.label }} &nbsp;
                <SortIcon
                  v-if="isSortable(header)"
                  :sortDirection="getSortDirection(header.key)"
                />
              </div>
            </div>
            <template v-if="dataIsLoading">
              <div class="item-row loading">
                <div class="loading-message">
                  Loading...
                  <i class="fa fa-arrows-rotate rotate-animation"></i>
                </div>
              </div>
            </template>
            <template v-else-if="fetchError">
              <div class="item-row error">
                <div class="error-message">{{ fetchError }} 😨</div>
              </div>
            </template>
            <template v-else-if="sortedOrders.length === 0">
              <div class="item-row no-data">
                <div class="no-data-message">No orders found 🤔</div>
              </div>
            </template>
            <template v-else>
              <div
                v-for="order in sortedOrders.slice(0, currentDisplayCount)"
                :key="order.Id"
                class="item-row"
              >
                <div
                  v-for="header in currentOrderHeaders"
                  :key="header.key"
                  class="list-item"
                >
                  <span class="item-label">{{ header.label }}:</span>
                  <span
                    class="item-value"
                    :class="{ 'order-note': header.key === 'OrderNote' }"
                    v-html="getOrderDetail(order, header.key)"
                  ></span>
                </div>
              </div>
              <div class="show-more-container">
                <button
                  v-if="hasMoreOrders"
                  @click="loadMoreOrders"
                  class="btn btn-primary show-more-btn"
                  :disabled="loadingMore"
                >
                  <span v-if="!loadingMore">Show More</span>
                  <span v-else>
                    Loading...
                    <i class="fa fa-arrows-rotate rotate-animation"></i>
                  </span>
                </button>
              </div>
            </template>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject, onMounted, onBeforeUnmount } from "vue";
import axios from "axios";
import SortIcon from "@/components/General/SortingIcon.vue";
import { errorToast } from "@/utils/toast-utils.js";
import { debounce, formattedTimeDifference } from "@/utils/utils.js";
import { storeToRefs } from "pinia";
import { useOrderFormStore } from "@/stores/orderFormStore";

const API_URL = process.env.VUE_APP_API_URL;
const EventBus = inject("EventBus");
const isOnline = inject("onlineStatus");
const store = useOrderFormStore();
const { userStoreId, posCode, userId } = storeToRefs(store);

const showModal = ref(false);
const dataIsLoading = ref(true);
const fetchError = ref(null);
const allOrders = ref([]);
const searchQuery = ref("");
const sortBy = ref("CreationDate");
const sortOrder = ref("desc");
const pageSize = 4;
const currentDisplayCount = ref(pageSize);
const hasMoreOrders = ref(true);
const loadingMore = ref(false);
const isUserOrderHistory = ref(false);

const orderHeaders = [
  { key: "CreationDate", label: "Order Date", sortable: true },
  { key: "User.FullName", label: "Sale Person Name", sortable: true },
  { key: "OrderNote", label: "Order Note", sortable: false },
  { key: "OrderPDF", label: "Order PDF", sortable: false },
];

const userOrderHeaders = [
  { key: "CreationDate", label: "Order Date", sortable: true },
  { key: "PointOfSale.Code", label: "Point of Sale Code", sortable: true },
  { key: "OrderNote", label: "Order Note", sortable: false },
  { key: "OrderPDF", label: "Order PDF", sortable: false },
];

const currentOrderHeaders = computed(() =>
  isUserOrderHistory.value ? userOrderHeaders : orderHeaders
);
const modeDescription = computed(() =>
  isUserOrderHistory.value
    ? "View and manage your personal order history across all points of sale."
    : `View and manage orders for the current point of sale: ${posCode.value}.`
);

const isSortable = (header) => {
  return header.sortable && sortedOrders.value.length > 1;
};

function getSortDirection(key) {
  if (
    sortBy.value !== key ||
    !isSortable(currentOrderHeaders.value.find((h) => h.key === key))
  ) {
    return "";
  }
  return sortOrder.value;
}

const modalTitle = computed(() =>
  isUserOrderHistory.value
    ? "My Order History"
    : "Orders History for: " + posCode.value
);

const modalClasses = computed(() => ({
  "modal fade show": showModal.value,
  "modal fade": !showModal.value,
}));

const sortedOrders = computed(() => {
  return [...allOrders.value].sort((a, b) => {
    if (
      !sortBy.value ||
      !currentOrderHeaders.value.find((h) => h.key === sortBy.value)?.sortable
    ) {
      return 0;
    }

    let aValue = a[sortBy.value];
    let bValue = b[sortBy.value];

    if (sortBy.value.includes(".")) {
      const keys = sortBy.value.split(".");
      aValue = keys.reduce((obj, key) => obj?.[key], a);
      bValue = keys.reduce((obj, key) => obj?.[key], b);
    }

    if (aValue < bValue) return sortOrder.value === "asc" ? -1 : 1;
    if (aValue > bValue) return sortOrder.value === "asc" ? 1 : -1;
    return 0;
  });
});

const debounceSearch = debounce(() => {
  currentDisplayCount.value = pageSize;
  fetchOrderList(true);
}, 300);

async function openModalFun(isUserHistory = false) {
  if (!isOnline.value) {
    errorToast(
      "You are offline. Please connect to the internet to view order history."
    );
    return;
  }
  try {
    showModal.value = true;
    isUserOrderHistory.value = isUserHistory;
    validateUserAndStore();
    resetModalState();
    await fetchOrderList(true);
  } catch (error) {
    console.error("Failed to fetch order list:", error);
    fetchError.value = "Failed to fetch order list. Please try again later.";
  } finally {
    dataIsLoading.value = false;
    if (document.body) {
      document.body.style.overflow = "hidden";
    }
  }
}

function validateUserAndStore() {
  if (!userStoreId.value) {
    throw new Error("Invalid user store ID.");
  }
  if (!posCode.value && !userId.value) {
    throw new Error("Invalid POS code or user ID.");
  }
}

function resetModalState() {
  dataIsLoading.value = true;
  fetchError.value = null;
  currentDisplayCount.value = pageSize;
  allOrders.value = [];
  hasMoreOrders.value = true;
}

function closeModal() {
  if (document.body) {
    document.body.style.overflow = "";
  }
  showModal.value = false;
}

async function fetchOrderList(isInitialFetch = false) {
  try {
    const response = await axios.post(
      `${API_URL}/GetOrders`,
      buildRequestPayload(isInitialFetch),
      {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      }
    );
    updateOrderList(response.data.data, isInitialFetch);
  } catch (err) {
    handleFetchError(err);
  }
}

function buildRequestPayload(isInitialFetch) {
  return {
    storeId: userStoreId.value,
    filters: isUserOrderHistory.value
      ? { userId: userId.value }
      : { pointOfSaleCode: posCode.value },
    includes: { includeUsers: true },
    sort: {
      sortBy: sortBy.value,
      sortOrder: sortOrder.value,
    },
    pagination: {
      pageNumber: 0,
      pageSize: isInitialFetch ? pageSize : currentDisplayCount.value,
    },
    search: { searchParams: searchQuery.value },
  };
}

function updateOrderList(newOrders, isInitialFetch) {
  allOrders.value = newOrders;
  hasMoreOrders.value =
    newOrders.length ===
    (isInitialFetch ? pageSize : currentDisplayCount.value);
}

function handleFetchError(err) {
  console.error("Cannot get order data from the server:", err.message);
  fetchError.value = "Failed to fetch order list. Please try again later.";
}

async function loadMoreOrders() {
  if (loadingMore.value || !hasMoreOrders.value) return;
  loadingMore.value = true;
  currentDisplayCount.value += pageSize;
  try {
    await fetchOrderList();
  } catch (error) {
    console.error("Failed to load more orders:", error);
    errorToast("Failed to load more orders. Please try again.");
  } finally {
    loadingMore.value = false;
  }
}

function sortOrders(key) {
  const header = currentOrderHeaders.value.find((h) => h.key === key);
  if (!isSortable(header)) {
    return;
  }

  if (sortBy.value === key) {
    sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
  } else {
    sortBy.value = key;
    sortOrder.value = "asc";
  }

  fetchOrderList();
}

function getOrderDetail(order, key) {
  switch (key) {
    case "CreationDate":
      return order.CreationDate
        ? formattedTimeDifference(order.CreationDate)
        : "";
    case "User.FullName":
      return order.User?.FullName ? `${order.User.FullName}` : "";
    case "PointOfSale.Code":
      return order.PointOfSale?.Code?.trim()
        ? `${order.PointOfSale?.Code}`
        : "";
    case "OrderNote":
      return order.OrderNote && order.OrderNote != null
        ? `<div class="note-content">${order.OrderNote}</div>`
        : "";
    case "OrderPDF":
      return order.PdfUlr
        ? `<a href="${order.PdfUlr}" target="_blank" rel="noopener noreferrer">Open PDF</a>`
        : "";
    default:
      return "";
  }
}

onMounted(() => {
  EventBus.$on("show-order-history-modal", openModalFun);
  EventBus.$on("show-user-order-history-modal", () => openModalFun(true));
});

onBeforeUnmount(() => {
  EventBus.$off("show-order-history-modal", openModalFun);
  EventBus.$off("show-user-order-history-modal", () => openModalFun(true));
});
</script>

<style lang="scss">
.order-note {
  max-height: 65px;
  overflow-y: auto;
  padding: 5px;
}

.header-item {
  display: inline-flex;
  &.sortable {
    cursor: pointer;
  }
}
.item-value a {
  color: $primary;
  text-decoration: none;
}

.item-value a:hover {
  text-decoration: underline;
}

.note-content {
  white-space: pre-wrap;
}

@media (max-width: 480px) {
  .order-note {
    max-height: 150px;
  }
}
</style>