<template>
  <div class="input-group quantity-wrapper">
    <span class="input-group-btn">
      <button
        type="button"
        class="quantity-left-minus btn btn-default btn-number"
        data-type="minus"
        title="Decrease the quantity"
        ref="decreaseButton"
        tabindex="0"
        @click="decreaseQuantity"
        @keydown.enter="decreaseQuantity"
      >
        <i class="fas fa-minus" role="button" />
      </button>
    </span>
    <input
      :value="inputValue === null ? '' : inputValue.toString()"
      ref="inputFieldRef"
      class="form-control form-control-sm input-quantity no-spinner"
      @input="handleInput"
      @blur="handleBlur"
      @focus="handleFocus"
      @keydown.enter.prevent="emit('enterPressed')"
      type="number"
      title="Set the quantity"
      :min="minValue"
      tabindex="0"
      autocomplete="off"
    />
    <span class="input-group-btn">
      <button
        ref="increaseButton"
        type="button"
        class="quantity-right-plus btn btn-default btn-number"
        data-type="plus"
        title="Increase the quantity"
        tabindex="0"
        @keydown.enter="increaseQuantity"
        @click="increaseQuantity"
      >
        <i class="fas fa-plus" role="button" />
      </button>
    </span>
  </div>
</template>

<script setup>
import { ref, watch, nextTick, onUnmounted } from "vue";
import { debounce } from "@/utils/utils.js";

const props = defineProps({
  value: { type: [String, Number], required: true },
  minValue: { type: Number, required: true },
  placeholder: String,
});

const emit = defineEmits(["input", "enterPressed", "inputChanged"]);
const inputFieldRef = ref(null);
const inputValue = ref(Number(props.value));

watch(
  () => props.value,
  (newValue) => {
    inputValue.value = Number(newValue);
  }
);

const handleFocus = () => {
  if (inputValue.value === props.minValue) {
    inputValue.value = null;
  }
};

const handleBlur = () => {
  if (inputValue.value === null || isNaN(inputValue.value)) {
    inputValue.value = props.minValue;
    emit("inputChanged", props.minValue);
  } else {
    emit("inputChanged", inputValue.value);
  }
};

const handleInput = (event) => {
  const value = event.target.value === "" ? null : Number(event.target.value);
  debouncedHandleInput(value);
};

const debouncedHandleInput = debounce((value) => {
  if (value === null || isNaN(value) || value < props.minValue) {
    updateAndEmitValue(props.minValue);
  } else {
    updateAndEmitValue(value);
  }
}, 150);

const increaseQuantity = () => {
  if (inputValue.value < Number.MAX_SAFE_INTEGER) {
    updateAndEmitValue(inputValue.value + 1);
  }
};

const decreaseQuantity = () => {
  if (inputValue.value > props.minValue) {
    updateAndEmitValue(inputValue.value - 1);
  }
};

const updateAndEmitValue = (newValue) => {
  newValue = Math.max(newValue, props.minValue);
  nextTick(() => {
    inputValue.value = newValue;
    emit("inputChanged", newValue);
  });
};

const focusInput = () => {
  inputFieldRef.value.focus();
};

onUnmounted(() => {
  if (debouncedHandleInput.cancel) {
    debouncedHandleInput.cancel();
  }
});

defineExpose({
  focusInput,
});
</script>