import axios from "axios";
import EventBus from "@/services/eventBus.js";
import { WorkingItemRepo } from "./DbAccess/WorkingItemRepo";
import { FileUploadRepo } from "./DbAccess/FileUploadRepo";
import { errorToast, successToast } from "@/utils/toast-utils.js";
import { dataSyncService } from "@/services/dataSync";

export const submitOrder = async (store, orderItems, orderPdf, orderNotes) => {
  try {
    const validateResponse = await validateOrderPrerequisites(
      store,
      orderItems,
      orderNotes
    );
    if (!validateResponse.success) {
      throw new Error(validateResponse.message);
    }

    const simplifiedOrderItems = simplifyOrderItems(orderItems);
    const attachments = await FileUploadRepo.listAsAttahcments(
      validateResponse.pos.code
    );

    if (!orderNotes || orderNotes.trim() === "") {
      orderNotes = "";
    }

    const payload = {
      storeId: validateResponse.user.storeId,
      pointOfSale: validateResponse.pos,
      orderNotes: orderNotes,
      orderItems: simplifiedOrderItems,
      attachments: attachments,
    };

    var orderCode = await generateUniqueCode(payload);
    payload.orderCode = orderCode;
    payload.orderPdf = orderPdf;

    const response = await axios.post(
      process.env.VUE_APP_API_URL + "/Order",
      payload,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    var orderData = response.data;
    if (orderData) {
      await handleOrderSuccess(orderData, validateResponse.pos.code, store);
    }
  } catch (error) {
    handleOrderError(error);
  }
};

const generateUniqueCode = async (payload) => {
  try {
    const payloadString = JSON.stringify(payload);
    const formattedDate = new Date().toISOString().split("T")[0];
    const data = payloadString + formattedDate;
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);
    const hashBuffer = await crypto.subtle.digest("SHA-256", dataBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
  } catch (error) {
    console.error("Error generating order code: ", error);
    return "";
  }
};
const simplifyOrderItems = (orderItems) => {
  return orderItems
    .filter(
      (item) =>
        item &&
        item.product &&
        item.product.Id &&
        item.product.ProductCode &&
        item.quantity > 0
    )
    .map((item) => ({
      id: item.product.Id,
      quantity: item.quantity,
      notes: !item.notes || item.notes.trim() === "" ? "" : item.notes,
    }));
};

const validateOrderPrerequisites = async (store, orderItems, orderNotes) => {
  if (navigator.onLine == false) {
    return {
      user: null,
      pos: null,
      success: false,
      message:
        "Online connection with server is required to perform this action.",
    };
  }
  if (
    orderItems.length === 0 &&
    (orderNotes == null || orderNotes === "" || orderNotes.trim() === "")
  ) {
    return {
      user: null,
      pos: null,
      success: false,
      message:
        "The order doesn't contain any items or notes. Please add items or notes to submit the order.",
    };
  }

  const user = await store.getUser();
  const pos = await store.getSelectedPointOfSale();

  if (!pos) {
    return {
      user: null,
      pos: null,
      success: false,
      message: "Please reload the app/page and try again.",
    };
  }

  if (!user) {
    errorToast("Please Login again and try to submit your order.");
    return {
      user: null,
      pos: null,
      success: false,
      message: "Please Login again and try to submit your order.",
    };
  }

  return {
    user: user,
    pos: pos,
    success: true,
    message: "",
  };
};

const handleOrderSuccess = async (data, pointOfSaleCode, store) => {
  EventBus.$emit("order-placed", data);
  successToast("Your order has been successfully placed.");
  await WorkingItemRepo.clear(pointOfSaleCode);
  await FileUploadRepo.clear(pointOfSaleCode);
  await dataSyncService.triggerSync(store, true, true);
};

const handleOrderError = (error) => {
  if (error.response && error.response.data) {
    EventBus.$emit("order-failed", error.response.data);
    console.error("Placing order error: ", error.response.data);
    errorToast(`Order Placement Failed.`);
    return;
  }
  if (error.name === "TypeError") {
    EventBus.$emit(
      "order-failed",
      "Placing order network error: Unable to complete the request."
    );
    console.error("Placing order network error: ", error);
  } else {
    EventBus.$emit("order-failed", error.message);
    console.error("Placing order error: ", error);
  }
  errorToast(`Order Placement Failed.`);
};
