<template>
  <div>
    <img
      v-if="showImage"
      :src="imageUrl"
      @error="onImageError"
      :alt="altText"
      :title="altText"
      :tabindex="tabIndex || 0"
      :class="['image-component', { 'zoom-in-cursor': allowPopUpOnClick }]"
      :style="{ width: widthStyle, height: heightStyle }"
      @keydown.enter="handleClick"
      @click="handleClick"
    />
    <span v-else>
      <i class="fas fa-image placeholder-icon" title="Image not available"></i>
    </span>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import EventBus from "@/services/eventBus";

const props = defineProps({
  baseUrl: {
    type: String,
    required: false,
  },
  title: String,
  width: Number,
  height: Number,
  round: String,
  tabIndex: Number,
  allowPopUpOnClick: {
    type: Boolean,
    default: false,
  },
});

const showImage = ref(true);
const productImage = ref(props.baseUrl);

const validImageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];

const isValidUrl = (url) => {
  if (!url) {
    return false;
  }
  try {
    const parsedUrl = new URL(url);
    if (parsedUrl.protocol !== "https:" && parsedUrl.protocol !== "http:")
      return false;

    const extension = parsedUrl.pathname.split(".").pop().toLowerCase();
    return validImageExtensions.includes(extension);
  } catch (e) {
    return false;
  }
};
const onImageError = () => {
  showImage.value = false;
};

const imageUrl = computed(() => {
  let url = productImage.value;
  const params = [];
  if (props.width) {
    params.push(`w-${props.width}`);
  }
  if (props.height) {
    params.push(`h-${props.height}`);
  }
  if (props.round) {
    params.push(`tr:r-${props.round}`);
  }
  if (params.length > 0) {
    url += `?tr=${params.join(",")}`;
  }
  return url;
});

watch(
  () => props.baseUrl,
  (newUrl) => {
    productImage.value = newUrl;
    showImage.value = isValidUrl(newUrl);
  },
  { immediate: true }
);

const handleClick = () => {
  if (props.allowPopUpOnClick) {
    EventBus.$emit("show-image-modal", props.baseUrl);
  }
};

const altText = computed(() => {
  return props.allowPopUpOnClick
    ? props.title
      ? `View ${props.title} image`
      : "View image"
    : props.title;
});

const widthStyle = computed(() => `${props.width || "auto"}`);
const heightStyle = computed(() => `${props.height || "auto"}`);
</script>