<template>
  <div class="input-group">
    <input
      class="form-control dropdown-toggle"
      ref="searchPosInputRef"
      name="search-pos-input"
      v-model.trim="state.searchTerm"
      autocomplete="off"
      @input="triggerSearchForPos"
      @focus="state.showDropdown = true"
      @keydown.up.prevent="handleDropdownNavigation(-1)"
      @keydown.down.prevent="handleDropdownNavigation(1)"
      @keydown.enter.prevent="selectPOSFromHighlight"
      placeholder="Search or enter new..."
      data-toggle="dropdown"
    />
    <div
      v-if="
        state.showDropdown && (state.searchResults.length || showAddNewItem)
      "
      class="pos-dropdown-menu dropdown-menu show"
      v-click-outside="closeDropdown"
    >
      <a
        v-for="(result, index) in state.searchResults"
        :key="result.code"
        :class="[
          'dropdown-item',
          'd-flex',
          'justify-content-between',
          'align-items-center',
          getDropdownItemClass(index + 1),
        ]"
        href="#"
        :title="result.imported ? 'Added By Admin' : 'Added By You'"
        @click.prevent="saveAndEmitPosIfValid(result.code, result.customerName)"
        @touchend.prevent="
          saveAndEmitPosIfValid(result.code, result.customerName)
        "
      >
        <span :title="result.code + ' - ' + result.customerName">
          {{ result.code }} -
          {{ formatCustomerName(result.customerName) }} </span
        >&nbsp;
        <span v-if="result.imported">
          <i class="fas fa-globe text-success"
        /></span>
        <span v-else><i class="fas fa-location-dot text-primary" /></span>
      </a>
      <div
        v-if="showAddNewItem && state.searchResults.length"
        class="dropdown-divider"
      ></div>
      <a
        href="#"
        index="-1"
        key="-1"
        v-if="showAddNewItem"
        :class="getDropdownItemClass(0)"
        @click.prevent="addNewOrUpdatePos()"
        @touchend.prevent="addNewOrUpdatePos()"
      >
        Add New POS : "{{ state.searchTerm }}"
        <i class="text-success fas fa-square-check" title="Add" />
      </a>
    </div>
    <button
      v-if="isValidPOS"
      class="btn btn-success"
      title="Add new Point of Sales Code"
      @click="addNewOrUpdatePos()"
      @touchend="addNewOrUpdatePos()"
    >
      <i class="fas fa-square-check" />
    </button>
  </div>
</template>
<script setup>
import { ref, reactive, computed, onMounted, nextTick } from "vue";
import { PointsOfSaleRepo } from "@/services/DbAccess/PointOfSaleRepo";
import { formatCustomerName, validInput } from "@/utils/utils";
import { useOrderFormStore } from "@/stores/orderFormStore";
import { storeToRefs } from "pinia";

const store = useOrderFormStore();
const { userStoreId } = storeToRefs(store);
const emit = defineEmits(["pos-selected"]);
const searchPosInputRef = ref(null);
const state = reactive({
  searchTerm: "",
  searchResults: [],
  showDropdown: false,
  highlightedIndex: -1,
});

const isValidPOS = computed(() => validInput(state.searchTerm));

const showAddNewItem = computed(() => {
  if (
    typeof isValidPOS.value === "boolean" &&
    Array.isArray(state.searchResults) &&
    typeof state.searchTerm === "string"
  ) {
    return (
      isValidPOS.value &&
      !state.searchResults.some(
        (result) => result.code.toUpperCase() === state.searchTerm.toUpperCase()
      )
    );
  }
  return false;
});
const triggerSearchForPos = async () => {
  try {
    if (!state.searchTerm) {
      state.searchResults = [];
      state.searchTerm = "";
    }

    state.highlightedIndex = -1;
    const result = await PointsOfSaleRepo.list(
      state.searchTerm.toLowerCase(),
      15,
      userStoreId.value
    );
    if (!result || result.length === 0) {
      state.searchResults = [];
      return;
    }
    result.sort((a, b) => {
      if (a.imported && !b.imported) return -1;
      if (b.imported && !a.imported) return 1;
      return 0;
    });

    state.searchResults = result.slice(0, 15);

    state.showDropdown =
      Array.isArray(state.searchResults) &&
      (isValidPOS.value || state.searchResults.length > 0);
  } catch (error) {
    console.error("Error triggerSearchForPos search results:", error);
  }
};

const handleDropdownNavigation = (direction) => {
  const maxIndex = state.searchResults.length;
  if (direction === 1 && state.highlightedIndex === maxIndex) {
    state.highlightedIndex = 0;
  } else if (direction === -1 && state.highlightedIndex <= 0) {
    state.highlightedIndex = maxIndex;
  } else {
    state.highlightedIndex += direction;
  }
};

const getDropdownItemClass = (index) => ({
  "dropdown-item-highlighted": state.highlightedIndex === index,
  "dropdown-item": true,
});

const selectPOSFromHighlight = async () => {
  if (state.highlightedIndex <= 0) {
    await addNewOrUpdatePos();
  } else {
    const highlightedPos = state.searchResults[state.highlightedIndex - 1];
    if (highlightedPos?.code) {
      await saveAndEmitPosIfValid(
        highlightedPos.code,
        highlightedPos.customerName
      );
    }
  }
};
const addNewOrUpdatePos = async () => {
  if (!isValidPOS.value) return;
  let existingPos = null;

  if (Array.isArray(state.searchResults)) {
    existingPos = state.searchResults.find(
      (result) => result.code.toUpperCase() === state.searchTerm.toUpperCase()
    );
  }
  if (existingPos && existingPos.code) {
    await saveAndEmitPosIfValid(existingPos.code, existingPos.customerName);
  } else {
    await saveAndEmitPosIfValid(state.searchTerm, null);
  }
};

const saveAndEmitPosIfValid = async (code, customerName) => {
  if (validInput(code)) {
    state.searchTerm = code;
    state.showDropdown = false;
  }
  emit("pos-selected", code, customerName);
};
const closeDropdown = () => {
  state.showDropdown = false;
};

onMounted(() => {
  if (searchPosInputRef.value) {
    searchPosInputRef.value.focus();
  } else {
    setTimeout(async () => {
      await nextTick();
      if (searchPosInputRef.value) {
        searchPosInputRef.value.focus();
      }
    }, 250);
  }
});
</script>