<template>
  <div :class="modalClasses" tabindex="-1">
    <div
      class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Device Sync & Data Management</h5>
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          <p class="text-muted">
            Manage your data across devices. Sync your current device or restore
            data from another.
          </p>
          <div class="item-list">
            <div class="item-header">
              <div
                v-for="header in sessionHeaders"
                :key="header"
                class="header-item"
              >
                {{ header }}
              </div>
            </div>
            <template v-if="dataIsLoading">
              <div class="item-row loading">
                <div class="loading-message">
                  Loading...
                  <i class="fa fa-arrows-rotate rotate-animation"></i>
                </div>
              </div>
            </template>
            <template v-else-if="fetchError">
              <div class="item-row error">
                <div class="error-message">{{ fetchError }} 😨</div>
              </div>
            </template>
            <template v-else-if="sessions.length === 0">
              <div class="item-row no-data">
                <div class="no-data-message">No devices found 🤔</div>
              </div>
            </template>
            <template v-else>
              <div
                v-for="session in sortedSessions"
                :key="session.SessionId"
                :class="[
                  'item-row',
                  { 'current-row': session.SessionId === sessionId },
                ]"
              >
                <div
                  v-for="(value, key) in getSessionDetails(session)"
                  :key="key"
                  class="list-item"
                  :title="
                    session.SessionId === sessionId ? 'Current Device' : ''
                  "
                >
                  <span class="item-label">{{ key }}:</span>
                  <span
                    v-if="key !== 'Action'"
                    class="item-value"
                    v-html="value"
                  ></span>
                  <span v-else class="item-value">
                    <button
                      v-if="session.SessionId !== sessionId"
                      class="btn btn-sm btn-primary me-2"
                      title="Restore data"
                      @click="restoreSession(session)"
                    >
                      Restore
                    </button>
                    <button
                      v-if="session.SessionId !== sessionId"
                      class="btn btn-sm btn-danger"
                      title="Delete session"
                      @click="confirmDeleteSession(session)"
                    >
                      Delete
                    </button>
                    <button
                      v-else
                      class="btn btn-sm btn-outline-primary"
                      :title="
                        syncStatusState.isSyncing
                          ? 'Sync in progress'
                          : 'Sync Now'
                      "
                      @click="forceSync"
                      :disabled="syncStatusState.isSyncing"
                    >
                      {{
                        syncStatusState.isSyncing ? "Syncing..." : "Sync Now"
                      }}
                    </button>
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <ConfirmDialog
    :show="showConfirmDialog"
    :title="confirmDialogTitle"
    :message="confirmDialogMessage"
    :details="confirmDialogDetails"
    :warning="confirmDialogWarning"
    :confirm-button-text="confirmDialogConfirmText"
    :cancel-button-text="confirmDialogCancelText"
    :confirm-button-class="confirmDialogConfirmClass"
    :cancel-button-class="confirmDialogCancelClass"
    @confirm="confirmAction"
    @cancel="cancelAction"
  />
</template>

<script setup>
import { ref, computed, inject, onMounted, onBeforeUnmount, watch } from "vue";
import { storeToRefs } from "pinia";
import { useOrderFormStore } from "@/stores/orderFormStore";
import axios from "axios";
import { formattedTimeDifference, getUTCDate } from "@/utils/utils.js";
import { dataSyncService } from "@/services/dataSync.js";
import { errorToast, successToast } from "@/utils/toast-utils.js";
import { getDeviceInfo } from "@/services/deviceInfo.js";
import ConfirmDialog from "@/components/General/ConfirmDialog.vue";

const API_URL = process.env.VUE_APP_API_URL;
const EventBus = inject("EventBus");
const isOnline = inject("onlineStatus");
const store = useOrderFormStore();
const { syncStatusState } = storeToRefs(store);

const showConfirmDialog = ref(false);
const confirmDialogTitle = ref("");
const confirmDialogMessage = ref("");
const confirmDialogDetails = ref([]);
const confirmDialogWarning = ref("");
const confirmDialogConfirmText = ref("Confirm");
const confirmDialogCancelText = ref("Cancel");
const confirmDialogConfirmClass = ref("btn btn-primary");
const confirmDialogCancelClass = ref("btn btn-secondary");

const sessionToRestore = ref(null);
const sessionToDelete = ref(null);
const sessions = ref([]);
const sessionId = ref("");
const showModal = ref(false);
const dataIsLoading = ref(true);
const fetchError = ref(null);

const sessionHeaders = ["Browser", "OS", "Platform", "Last Modified", "Action"];

const modalClasses = computed(() => ({
  "modal fade show": showModal.value,
  "modal fade": !showModal.value,
}));

const sortedSessions = computed(() => {
  return [...sessions.value].sort((a, b) => {
    if (a.SessionId === sessionId.value) return -1;
    if (b.SessionId === sessionId.value) return 1;
    return 0;
  });
});

async function openModalFun() {
  if (!isOnline.value) {
    errorToast("You are offline. Please connect to the internet to sync data.");
    return;
  }
  try {
    showModal.value = true;
    dataIsLoading.value = true;
    fetchError.value = null;
    const currentSession = await getCurrentDeviceSession();
    sessionId.value = currentSession.SessionId;
    await fetchSessionList(currentSession);
  } catch (error) {
    console.error("Failed to fetch device list:", error);
    fetchError.value = "Failed to fetch device list. Please try again later.";
  } finally {
    dataIsLoading.value = false;
    if (document.body) {
      document.body.style.overflow = "hidden";
    }
  }
}

function closeModal() {
  if (document.body) {
    document.body.style.overflow = "";
  }
  showModal.value = false;
}

async function fetchSessionList(currentSession, timeout = 6000) {
  try {
    const response = await Promise.race([
      axios.get(`${API_URL}/Sessions`, {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      }),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Request timeout")), timeout)
      ),
    ]);

    sessions.value = Array.isArray(response.data) ? response.data : [];

    if (
      currentSession &&
      !sessions.value.some(
        (session) => session.SessionId === currentSession.SessionId
      )
    ) {
      sessions.value.push(currentSession);
    }
  } catch (err) {
    console.error("Cannot get session data from the server:", err.message);
    fetchError.value = "Failed to fetch device list. Please try again later.";
    sessions.value = [];
  }
}

async function getCurrentDeviceSession() {
  const currentDeviceInfo = await getDeviceInfo();
  if (currentDeviceInfo) {
    return {
      SessionId: currentDeviceInfo.sessionId,
      DeviceInfo: {
        Browser: currentDeviceInfo.browser,
        BrowserVersion: currentDeviceInfo.browserVersion,
        ClientOS: currentDeviceInfo.clientOS,
        Platform: currentDeviceInfo.platform,
      },
      LastUpdateDate: getUTCDate(),
      Data: null,
    };
  }
  return null;
}

function getSessionDetails(session) {
  return {
    Browser: `${session.DeviceInfo?.Browser} ${session.DeviceInfo?.BrowserVersion}`,
    OS: session.DeviceInfo?.ClientOS,
    Platform: session.DeviceInfo?.Platform,
    "Last Modified":
      session.SessionId !== sessionId.value
        ? formattedTimeDifference(session.LastUpdateDate)
        : getSyncStatus(),
    Action: "button",
  };
}

function getSyncStatus() {
  if (syncStatusState.value.isSyncing) {
    return '<i class="fa fa-arrows-rotate rotate-animation"></i>';
  } else if (!syncStatusState.value.success) {
    return '<span class="sync-failed">Sync failed</span>';
  } else {
    return formattedTimeDifference(syncStatusState.value.lastSyncDateTime);
  }
}

function restoreSession(session) {
  sessionToRestore.value = session;
  showConfirmDialog.value = true;
  confirmDialogTitle.value = "Confirm Restore";
  confirmDialogMessage.value = `Are you sure you want to restore data from this device <strong>${getDeviceString(
    session
  )}</strong>?`;
  confirmDialogDetails.value = [
    "Replace your current local data with the selected device's data",
    "Overwrite any unsaved changes",
    "Require a page reload to apply changes",
    "Items included: OrderItems, Files, Notes",
  ];
  confirmDialogWarning.value =
    "<strong>This action cannot be undone. Do you want to proceed?</strong>";
  confirmDialogConfirmText.value = "Restore";
  confirmDialogConfirmClass.value = "btn btn-primary";
}

function confirmDeleteSession(session) {
  sessionToDelete.value = session;
  showConfirmDialog.value = true;
  confirmDialogTitle.value = "Confirm Delete";
  confirmDialogMessage.value = `Are you sure you want to delete the session for device <strong>${getDeviceString(
    session
  )}</strong>?`;
  confirmDialogWarning.value =
    "This action cannot be undone. Do you want to proceed?";
  confirmDialogConfirmText.value = "Delete";
  confirmDialogConfirmClass.value = "btn btn-danger";
}

function getDeviceString(session) {
  return `${session.DeviceInfo?.Browser} ${session.DeviceInfo?.Platform} On ${session.DeviceInfo?.ClientOS}`;
}

async function confirmAction() {
  showConfirmDialog.value = false;
  if (sessionToRestore.value) {
    await confirmRestore();
  } else if (sessionToDelete.value) {
    await deleteSession();
  }
}

function cancelAction() {
  showConfirmDialog.value = false;
  sessionToRestore.value = null;
  sessionToDelete.value = null;
}

async function confirmRestore() {
  try {
    let result = await dataSyncService.restoreDataFromServer(
      sessionToRestore.value.Data
    );
    if (!result) {
      errorToast("Failed to restore data from the server");
      return;
    }
    successToast("Data restored successfully. Reloading the app...");
    setTimeout(() => window.location.reload(), 1500);
  } catch (error) {
    errorToast("Failed to restore data from the server");
  } finally {
    sessionToRestore.value = null;
  }
}

async function deleteSession() {
  if (!sessionToDelete.value) return;

  try {
    await axios.delete(
      `${API_URL}/DeleteSession?sessionId=${sessionToDelete.value.SessionId}`,
      {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      }
    );
    sessions.value = sessions.value.filter(
      (s) => s.SessionId !== sessionToDelete.value.SessionId
    );
    successToast("Session deleted successfully");
  } catch (error) {
    console.error("Failed to delete session:", error);
    errorToast("Failed to delete session. Please try again later.");
  } finally {
    sessionToDelete.value = null;
  }
}

async function forceSync() {
  try {
    await dataSyncService.triggerSync(store, isOnline.value, true);
  } catch (error) {
    errorToast("Failed to sync data with the server");
  }
}

watch(
  () => syncStatusState.value,
  async () => {
    const currentSession = await getCurrentDeviceSession();
    if (currentSession) {
      const index = sessions.value.findIndex(
        (s) => s.SessionId === currentSession.SessionId
      );
      if (index !== -1) {
        sessions.value[index] = { ...sessions.value[index], ...currentSession };
      }
    }
  },
  { deep: true }
);

onMounted(() => {
  EventBus.$on("show-data-sync-modal", openModalFun);
});

onBeforeUnmount(() => {
  EventBus.$off("show-data-sync-modal", openModalFun);
});
</script>

<style lang="scss">
.sync-failed {
  color: $danger;
}
</style>