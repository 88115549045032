// src/services/dataSync.js
import axios from "axios";
import db from "./DbAccess/dbConfig";
import { getDeviceInfo } from "@/services/deviceInfo";
import { compressData, decompressData } from "@/utils/compressionUtils";
import { getUTCDate } from "@/utils/utils";

const TABLES = [
  "WorkingItems",
  "WorkingHistoryPointOfSale",
  "OrderNote",
  "Files",
];

const SYNC_COOLDOWN = 3 * 60 * 1000; // 3 minutes
const SYNC_ENDPOINT = `${process.env.VUE_APP_API_URL}/Sync`;

class DataSyncService {
  constructor() {
    this.restoringInProgress = false;
    this.syncInProgress = false;
  }

  async getAllTableData() {
    return Object.fromEntries(
      await Promise.all(
        TABLES.map(async (tableName) => [
          tableName,
          await db[tableName].toArray(),
        ])
      )
    );
  }

  async syncDataWithServer(compressedData, deviceInfo) {
    const response = await axios.post(
      SYNC_ENDPOINT,
      { data: compressedData, deviceInfo },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        timeout: 10000, // 10 seconds timeout
      }
    );

    if (response.status !== 200) {
      throw new Error(`SYNC API returned status ${response.status}`);
    }

    return response.data;
  }

  async getLastSyncTime() {
    const heartbeat = await db.SyncHeartBeat.get(1);
    return heartbeat
      ? getUTCDate(heartbeat.timestamp)
      : getUTCDate(Date.now() - 30 * 24 * 60 * 60 * 1000);
  }

  async updateSyncHeartbeat() {
    await db.SyncHeartBeat.put({ id: 1, timestamp: getUTCDate() });
  }

  async performSync(store, isOnline, forceSync) {
    if (this.restoringInProgress || this.syncInProgress) return;
    this.syncInProgress = true;
    try {
      const lastSyncTime = await this.getLastSyncTime();
      if (!isOnline) {
        store.setSyncStatus(isOnline, false, lastSyncTime);
        return;
      }

      if (!forceSync && getUTCDate() - lastSyncTime < SYNC_COOLDOWN) {
        store.setSyncStatus(isOnline, false, lastSyncTime);
        return;
      }

      store.setSyncStatus(true, true, lastSyncTime);
      const allData = await this.getAllTableData();
      const deviceInfo = await getDeviceInfo();

      if (!allData || !deviceInfo) {
        throw new Error("Failed to get data or device info");
      }

      const compressedData = compressData(allData);
      await this.syncDataWithServer(compressedData, deviceInfo);
      await this.updateSyncHeartbeat();
      store.setSyncStatus(true, false, getUTCDate());
    } catch (error) {
      const lastSyncTime = await this.getLastSyncTime();
      store.setSyncStatus(false, false, lastSyncTime);
      console.error("Error during sync:", error);
    } finally {
      this.syncInProgress = false;
    }
  }

  async restoreDataFromServer(sessionData) {
    if (this.restoringInProgress) return false;
    this.restoringInProgress = true;

    try {
      const decompressedData = decompressData(sessionData);
      if (!decompressedData) {
        return false;
      }

      await db.transaction("rw", db.tables, async () => {
        for (const tableName of TABLES) {
          await db[tableName].clear();
          await db[tableName].bulkAdd(decompressedData[tableName] || []);
        }
      });

      return true;
    } catch (error) {
      console.error("Failed to restore data from server", error);
      return false;
    } finally {
      this.restoringInProgress = false;
    }
  }

  async triggerSync(store, isOnline, forceSync = false) {
    return this.performSync(store, isOnline, forceSync);
  }
}

export const dataSyncService = new DataSyncService();
