// toast-utils.js
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
const toast = useToast();

const defaultOptions = {
  position: "top-right",
  timeout: 2500,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.29,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
  style: {
    fontSize: "13px",
  },
};
const errorOptions = {
  position: "top-right",
  timeout: 8000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.29,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
  style: {
    fontSize: "13px",
  },
};

export function infoToast(message, options = {}) {
  toast.info(message, { ...defaultOptions, ...options });
}

export function addToast(message, options = {}) {
  toast.success(message, { ...defaultOptions, ...options });
}
export function successToast(message, options = {}) {
  toast.success(message, { ...defaultOptions, ...options });
}

export function deleteToast(message, options = {}) {
  toast.error(message, { ...defaultOptions, ...options });
}

export function errorToast(message, options = {}) {
  toast.error(message, { ...errorOptions, ...options });
}
export function warningToast(message, options = {}) {
  toast.warning(message, { ...defaultOptions, ...options });
}

const toastOptions = {
  transition: "Vue-Toastification__fade",
  maxToasts: 2,
  newestOnTop: true,
  filterBeforeCreate: (newToast, toasts) => {
    try {
      if (!newToast || !Array.isArray(toasts)) {
        return false;
      }
      if (newToast.type != "error") {
        const existingToast = toasts.find((t) => t.type === newToast.type);
        if (existingToast) {
          existingToast.content = newToast.content;
          return false;
        }
      }
      if (
        toasts.some(
          (t) => t.content === newToast.content && t.type === newToast.type
        )
      ) {
        return false;
      }
      return newToast;
    } catch {
      return false;
    }
  },
};

export { toastOptions };
