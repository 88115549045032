<template>
  <div v-if="hasProduct" ref="mobileRowRef" class="mobile-row-min">
    <div class="collapsed-view">
      <ImageComponent
        :base-url="item?.product?.MainPhotoUrl"
        :title="item?.product?.Title"
        :width="45"
        :height="45"
        round="max"
        class="product-image"
        :allow-pop-up-on-click="true"
      />
      <span class="quantity text-bold"> Q: {{ item?.quantity }} </span>
      <span class="product-code text-bold">
        {{ item.product.ProductCode }}
      </span>
      <span class="product-title" :title="item.product.Title">
        {{ shortTitle }}
      </span>
      <span @click="toggleView" class="toggle-view-btn">
        <i class="fas fa-chevron-down" role="button" />
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import ImageComponent from "@/components/General/ImageComponent.vue";

const props = defineProps({
  item: { type: Object, required: true },
});

const emit = defineEmits(["toggle-view"]);

const shortTitle = computed(() => {
  if (!hasProduct.value) return "";

  const originalTitle = props.item.product.Title;
  const maxLength = 14;

  if (originalTitle.length > maxLength) {
    return `${originalTitle.substring(0, maxLength)}...`;
  }

  return originalTitle.padEnd(maxLength, "\u00A0");
});

const hasProduct = computed(
  () => props.item.product && props.item.product.ProductCode
);

const toggleView = () => emit("toggle-view");
</script>