<template>
  <td v-if="hasPOS">
    <input-field
      v-model="localQuantity"
      @inputChanged="updateQuantityFiled"
      ref="inputFieldRef"
      class="input-width"
      name="quantity-input"
      :value="localQuantity"
      :min-value="0"
      autocomplete="off"
    />
  </td>
  <td>
    <div class="mt-2">
      <a
        role="button"
        href="#"
        class="product-code-link"
        :title="'Show ' + product.ProductCode + ' image'"
        @click="openImageModal(product?.MainPhotoUrl)"
      >
        {{ product.ProductCode }}
      </a>
    </div>
  </td>
  <td>
    <div class="mt-2">
      {{ product.Title }}
    </div>
  </td>
  <td v-if="hasPOS">
    <button
      v-if="localQuantity > 0"
      ref="popoverButton"
      class="btn-no-style btn-sm pop-over-button mt-2"
      @click="togglePopover"
    >
      <span><i class="fas fa-comment text-primary" /></span>
    </button>

    <note-popover
      :target="popoverButton"
      :title="product.Title"
      :isVisible="isPopoverVisible"
      @update:isVisible="isPopoverVisible = $event"
    >
      <textarea
        class="form-control pop-over-text-area"
        v-model="localNotes"
      ></textarea>
      <button class="btn btn-success" @click="saveNote">Save</button>
    </note-popover>
  </td>
  <td v-if="!isMobile">
    <image-component
      :baseUrl="product?.MainPhotoUrl"
      :width="Number(45)"
      :height="Number(45)"
      :tab-index="0"
      :title="product?.Title"
      :allow-pop-up-on-click="true"
    ></image-component>
  </td>
</template>

<script setup>
import { ref, watch, inject, onMounted } from "vue";
import InputField from "@/components/General/InputField.vue";
import NotePopover from "@/components/Products/ProductCatalog/NotePopover.vue";
import ImageComponent from "@/components/General/ImageComponent.vue";
import { storeToRefs } from "pinia";
import { useOrderFormStore } from "@/stores/orderFormStore";

const store = useOrderFormStore();
const { hasPOS } = storeToRefs(store);

const props = defineProps({
  product: { type: Object, required: true },
  index: { type: Number, required: true },
  selectedItem: { type: Object, required: false },
});

const isMobile = inject("isMobile");
const EventBus = inject("EventBus");
const emit = defineEmits(["product-updated"]);
const localQuantity = ref(0);
const popoverButton = ref(null);
const isPopoverVisible = ref(false);
const localNotes = ref("");

watch(
  () => props.selectedItem,
  (newValue) => {
    localQuantity.value = newValue ? newValue.quantity : 0;
    localNotes.value = newValue ? newValue.notes : "";
  }
);

const updateQuantityFiled = (value) => {
  EventBus.$emit("product-catalog-change", {
    quantity: value,
    fieldType: "quantity",
    productCode: props.product.ProductCode,
  });
  setTimeout(() => {
    emit("product-updated");
  }, 50);
};

const togglePopover = () => {
  isPopoverVisible.value = !isPopoverVisible.value;
};

const saveNote = () => {
  EventBus.$emit("product-catalog-change", {
    notes: localNotes.value,
    fieldType: "notes",
    productCode: props.product.ProductCode,
  });
  setTimeout(() => {
    emit("product-updated");
  }, 50);

  isPopoverVisible.value = false;
};

const openImageModal = (url) => {
  EventBus.$emit("show-image-modal", url);
};

onMounted(() => {
  localQuantity.value = props.selectedItem ? props.selectedItem.quantity : 0;
  localNotes.value = props.selectedItem ? props.selectedItem.notes : "";
});
</script>
  