<template>
  <div class="order-form-section">
    <div class="item-row-header-ps">
      <span>Order Items</span>
      <a
        v-if="hasValidItems"
        href="#/"
        @click="openClearWorkingItemsModal"
        class="text-decoration-none"
        title="Clear working items"
      >
        Clear <i class="fas fa-eraser" />
      </a>
    </div>
    <div>
      <div class="item-row-header">
        <div class="col-quantity">Quantity</div>
        <div
          class="col-code product-code-sort prevent-select"
          :title="sortProductTitle"
          @click="toggleSortDirection"
        >
          <span>Code</span>
          <SortingIcon
            v-if="items?.length > 1"
            :sortDirection="sortDirection"
          />
        </div>
        <div class="col-description">Description</div>
        <div class="col-notes">Notes</div>
        <div class="col-image">Image</div>
        <div class="col-empty"></div>
      </div>

      <div v-if="dataIsLoading" key="-1">
        Loading... <i class="fa fa-arrows-rotate rotate-animation" />
      </div>

      <ItemRow
        v-else
        v-for="item in items"
        :key="item.uId"
        :item="item"
        :index="item.uId"
        v-memo="[
          item.product?.ProductCode,
          item.quantity,
          item.notes,
          item.editing,
        ]"
        @input-changed="onInputChanged"
        @remove-row="removeRow"
        @edit-product="onEditProduct"
        @product-selected="onProductSelected"
      ></ItemRow>

      <div class="add-product-buttons">
        <button
          @click="addRow"
          ref="addRowButton"
          class="btn btn-outline-primary btn-sm me-2"
          title="Add single product"
        >
          Add Single <i class="fas fa-add" />
        </button>
        <button
          @click="addMultiple"
          ref="addMultiple"
          class="btn btn-outline-success btn-sm"
          title="Browse and add one or multiple products"
        >
          Add multiple Products <i class="fas fa-folder-plus" />
        </button>
      </div>
    </div>
    <ConfirmDialog
      :show="showConfirmDialog"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :confirm-button-text="confirmDialogConfirmText"
      :cancel-button-text="confirmDialogCancelText"
      :confirm-button-class="confirmDialogConfirmClass"
      @confirm="confirmClearedItems"
      @cancel="closeClearWorkingItemsModal"
    />
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  toRaw,
  inject,
} from "vue";
import ItemRow from "./ItemRowComponents/ItemRow.vue";
import ConfirmDialog from "@/components/General/ConfirmDialog.vue";
import SortingIcon from "@/components/General/SortingIcon.vue";
import {
  infoToast,
  addToast,
  deleteToast,
  successToast,
  errorToast,
} from "@/utils/toast-utils.js";
import { WorkingItemRepo } from "@/services/DbAccess/WorkingItemRepo";
import { OrderNoteRepo } from "@/services/DbAccess/OrderNoteRepo";
import { ProductRepo } from "@/services/DbAccess/ProductRepo";
import { searchAndFilterProducts, createNewItem } from "@/utils/productUtils";
import { useOrderFormStore } from "@/stores/orderFormStore";
import { FileUploadRepo } from "@/services/DbAccess/FileUploadRepo";

const props = defineProps({
  pointOfSaleCode: { type: String, required: true },
});

const store = useOrderFormStore();
const EventBus = inject("EventBus");

const items = ref([]);
const dataIsLoading = ref(true);
const sortDirection = ref("none");
const sortProductTitle = ref("Click to Sort Code A-Z");
const showConfirmDialog = ref(false);
const confirmDialogTitle = ref("Clear Working Items");
const confirmDialogMessage = ref("");
const confirmDialogConfirmText = ref("Confirm Clear");
const confirmDialogCancelText = ref("Close");
const confirmDialogConfirmClass = ref("btn btn-danger");
const addRowButton = ref(null);

const hasValidItems = computed(() =>
  items.value.some((item) => item?.product?.ProductCode)
);

// Helper functions
const getItemByUId = (uId) => items.value.find((item) => item.uId === uId);

const sortFn = (a, b) => {
  if (!a?.product?.ProductCode) return -1;
  if (!b?.product?.ProductCode) return 1;
  return a.product.ProductCode.localeCompare(b.product.ProductCode, undefined, {
    numeric: true,
    sensitivity: "base",
  });
};

const openClearWorkingItemsModal = () => {
  confirmDialogMessage.value = `
    By clicking Confirm, all items added to the current Point of Sale
    together with the uploaded files and order notes will be
    cleared.<br /><br />
    Point of Sale Code: <strong>${props.pointOfSaleCode}</strong>
  `;
  showConfirmDialog.value = true;
};

const closeClearWorkingItemsModal = () => {
  showConfirmDialog.value = false;
};

const toggleSortDirection = async () => {
  if (sortDirection.value === "none") {
    sortDirection.value = "asc";
    sortProductTitle.value = "Click to Sort Code Z-A";
    items.value.sort(sortFn);
  } else if (sortDirection.value === "asc") {
    sortDirection.value = "desc";
    sortProductTitle.value = "Click to Remove Sorting";
    items.value.sort((a, b) => -sortFn(a, b));
  } else {
    sortDirection.value = "none";
    sortProductTitle.value = "Click to Sort Code A-Z";
    await reloadItems();
  }
};

const confirmClearedItems = async () => {
  try {
    await WorkingItemRepo.clear(props.pointOfSaleCode);
    await OrderNoteRepo.clear(props.pointOfSaleCode);
    await FileUploadRepo.clear(props.pointOfSaleCode);
    EventBus.$emit("working-items-cleared");
    await reloadItems();
    successToast("Working items have been cleared.");
    closeClearWorkingItemsModal();
    store.updateHistory();
  } catch (error) {
    console.error("Error clearing working items:", error);
    errorToast("Failed to clear working items. Please try again.");
  }
};

const reloadItems = async () => {
  try {
    items.value = await WorkingItemRepo.list(props.pointOfSaleCode);
  } catch (error) {
    console.error("Error loading items:", error);
    errorToast("Error loading working items. Please try again.");
  }
};

const addMultiple = () => EventBus.$emit("show-product-catalog-modal");

const addRow = async () => {
  const newItem = createNewItem(props.pointOfSaleCode);
  items.value.push(newItem);
  await WorkingItemRepo.save(toRaw(newItem));
  store.updateHistory();
};

const removeRow = async (uId) => {
  const itemToRemove = getItemByUId(uId);
  if (itemToRemove) {
    await WorkingItemRepo.delete(itemToRemove.id);
    const title = itemToRemove.product?.ProductCode ?? "Item";
    deleteToast(`${title} has been removed.`);
  }
  items.value = items.value.filter((item) => item.uId !== uId);
  store.updateHistory();
};

const onEditProduct = (uId) => {
  const item = getItemByUId(uId);
  if (item) item.editing = true;
};

const onInputChanged = async (payload) => {
  const currentItem = getItemByUId(payload.index);
  if (!currentItem) return;

  if (payload.fieldType === "searchTerm") {
    currentItem.searchTerm = payload.value;
    await searchProduct(payload.index);
  } else if (payload.fieldType === "quantity") {
    currentItem.quantity = payload.value;
  } else if (payload.fieldType === "notes") {
    currentItem.notes = payload.value;
  }
  await WorkingItemRepo.save(toRaw(currentItem));
  store.updateHistory();
};

const searchProduct = async (index) => {
  const currentItem = getItemByUId(index);
  if (!currentItem) return;

  const { searchTerm } = currentItem;
  if (!searchTerm?.trim()) {
    currentItem.product = null;
    currentItem.searchResults = [];
    return;
  }

  try {
    const search = await searchAndFilterProducts(searchTerm, items.value);
    if (search.error) {
      console.error(search.error);
    } else {
      currentItem.searchResults = search.items;
    }
  } catch (error) {
    console.error("Error searching products:", error);
  }
};

const onProductSelected = async (payload) => {
  const currentItem = getItemByUId(payload.rowIndex);
  if (!currentItem) return;

  const currentProduct = toRaw(payload.product);
  if (!currentProduct) return;

  if (
    !currentItem.product ||
    currentProduct.ProductCode !== currentItem.product.ProductCode
  ) {
    currentItem.quantity = 1;
    currentItem.notes = "";
  }

  currentItem.product = payload.product;
  currentItem.searchResults = [];
  currentItem.editing = false;

  await WorkingItemRepo.save(toRaw(currentItem));
  store.updateHistory();
};

const onProductCheckToggled = async (payload) => {
  if (!payload) return;
  const product = await ProductRepo.find(payload.productCode);
  if (!product) return;

  const title = product.ProductCode ?? "Item";
  const existingItem = items.value.find(
    (item) => item.product?.Id === product.Id
  );

  if (payload.fieldType === "quantity") {
    if (existingItem) {
      if (payload.quantity <= 0) {
        await removeRow(existingItem.uId);
        deleteToast(`${title} has been removed.`);
      } else {
        existingItem.quantity = payload.quantity;
        existingItem.editing = false;
        await WorkingItemRepo.save(toRaw(existingItem));
        infoToast(`${title} has been updated.`);
      }
    } else if (payload.quantity > 0) {
      const newItem = createNewItem(props.pointOfSaleCode, toRaw(product));
      newItem.quantity = payload.quantity;
      items.value.push(newItem);
      await WorkingItemRepo.save(toRaw(newItem));
      addToast(`${title} has been added.`);
    }
  } else if (payload.fieldType === "notes" && existingItem) {
    existingItem.notes = payload.notes;
    existingItem.editing = false;
    await WorkingItemRepo.save(toRaw(existingItem));
    infoToast(`${title} Notes has been saved.`);
  }
  store.updateHistory();
};

onMounted(async () => {
  await reloadItems();
  dataIsLoading.value = false;
  EventBus.$on("product-catalog-change", onProductCheckToggled);
  EventBus.$on("order-placed", confirmClearedItems);
});

onBeforeUnmount(() => {
  EventBus.$off("product-catalog-change", onProductCheckToggled);
  EventBus.$off("order-placed", confirmClearedItems);
});

watch(
  () => props.pointOfSaleCode,
  async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      await reloadItems();
    }
  }
);
</script>