<template>
  <div class="selected-items">
    Selected Items: &nbsp;
    <span class="text-bold">{{ filteredSelectedItemsCount }}</span>
    <span v-if="showTooltipIcon" :title="selectedItemsTooltip">
      <i class="ms-2 text-primary fas fa-info-circle" />
    </span>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { findById } from "@/utils/utils";

const props = defineProps({
  selectedItems: {
    type: Array,
    default: () => [],
  },
  selectedBrand: {
    type: [String, Number],
    default: () => null,
  },
  selectedCategory: {
    type: [String, Number],
    default: () => null,
  },
  searchTerm: {
    type: String,
    default: () => "",
  },
  brands: {
    type: Array,
    default: () => [],
  },
  categories: {
    type: Array,
    default: () => [],
  },
});

const filteredSelectedItems = computed(() => {
  const normalizedSearchTerm =
    props.searchTerm && props.searchTerm.trim().length > 0
      ? props.searchTerm.toLowerCase()
      : "";
  return props.selectedItems.filter((item) => {
    const matchesBrand =
      !props.selectedBrand || item.product.BrandId == props.selectedBrand;
    const matchesCategory =
      !props.selectedCategory ||
      item.product.CategoryId == props.selectedCategory;

    const productName = item.product.Title
      ? item.product.Title.toLowerCase()
      : "";
    const productCode = item.product.ProductCode
      ? item.product.ProductCode.toLowerCase()
      : "";
    const matchesSearchTerm =
      !normalizedSearchTerm ||
      productName.includes(normalizedSearchTerm) ||
      productCode.includes(normalizedSearchTerm);

    return matchesBrand && matchesCategory && matchesSearchTerm;
  });
});

const filteredSelectedItemsCount = computed(
  () => filteredSelectedItems.value?.length
);

const currentBrand = computed(() =>
  findById(props.brands, props.selectedBrand)
);
const currentCategory = computed(() =>
  findById(props.categories, props.selectedCategory)
);

const selectedItemsTooltip = computed(() => {
  let tooltip = `Selected Items: ${filteredSelectedItemsCount.value}`;

  if (currentBrand.value) {
    tooltip += ` in Brand: ${currentBrand.value.Name}`;
  }

  if (currentCategory.value) {
    tooltip += ` in Category: ${currentCategory.value.Name}`;
  }

  if (props.searchTerm && props.searchTerm.trim().length > 0) {
    tooltip += ` with search: ${props.searchTerm}`;
  }

  return tooltip;
});

const showTooltipIcon = computed(() => {
  return (
    currentBrand.value ||
    currentCategory.value ||
    (props.searchTerm && props.searchTerm.trim().length > 0)
  );
});
</script>