<template>
  <div>
    <div>
      <div
        tabindex="-1"
        :class="{ 'modal fade show': showModal, 'modal fade': !showModal }"
      >
        <div
          class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered"
        >
          <div class="modal-content">
            <ProductCatalogHeader
              :posCode="posCode"
              :selectedItems="selectedItems"
              :brands="brands"
              :categories="categories"
              :isLargeScreen="isLargeScreen"
              @close-modal="closeModal"
              @update:selectedBrand="updateEvent('selectedBrand', $event)"
              @update:selectedCategory="updateEvent('selectedCategory', $event)"
              @update:searchTerm="updateEvent('searchTerm', $event)"
            />
            <div class="modal-body" ref="modalBody" v-show="initialized">
              <div class="row">
                <table class="table table-responsive">
                  <thead>
                    <tr>
                      <th v-if="hasPOS">Quantity</th>
                      <th
                        @click="toggleSortDirection"
                        class="product-code-sort prevent-select"
                        :title="sortProductTitle"
                      >
                        <span>Code</span>
                        <SortingIcon :sortDirection="sortDirection" />
                      </th>

                      <th>Name</th>
                      <th v-if="hasPOS">Note</th>
                      <th v-if="isLargeScreen">Img</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(product, index) in productCatalog"
                      :key="product?.ProductCode"
                      v-memo="[product.Id, getSelectedItem(product.Id)]"
                    >
                      <product-catalog-row
                        :index="index"
                        :product="product"
                        :selectedItem="getSelectedItem(product.Id)"
                        @product-updated="handleProductUpdate"
                      ></product-catalog-row>
                    </tr>
                    <tr ref="loadingTrigger" v-show="!noLoadMore">
                      <td colspan="5">
                        <div v-if="dataIsLoading">
                          Loading...
                          <i class="fa fa-arrows-rotate rotate-animation" />
                        </div>
                      </td>
                    </tr>
                    <tr v-show="noLoadMore">
                      <td colspan="5" class="text-center">
                        <div>
                          {{ getLastRowText }}
                          <i class="text-success fas fa-square-check" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  shallowRef,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  inject,
} from "vue";

import ProductCatalogRow from "@/components/Products/ProductCatalog/ProductCatalogRow.vue";
import ProductCatalogHeader from "@/components/Products/ProductCatalog/ProductCatalogHeader.vue";
import SortingIcon from "@/components/General/SortingIcon.vue";
import { WorkingItemRepo } from "@/services/DbAccess/WorkingItemRepo";
import { ProductRepo } from "@/services/DbAccess/ProductRepo";
import { storeToRefs } from "pinia";
import { useOrderFormStore } from "@/stores/orderFormStore";
import { findById } from "@/utils/utils";

const EventBus = inject("EventBus");
const store = useOrderFormStore();
const { posCode, hasPOS } = storeToRefs(store);

const emit = defineEmits(["modal-closed"]);
const isMobile = inject("isMobile");
const showModal = ref(false);
const sortDirection = ref("none");
const sortProductTitle = ref("Click to Sort Code A-Z");
const searchTerm = ref("");
const dataIsLoading = ref(true);
const initialized = ref(false);
const noLoadMore = ref(false);
const pageNumber = ref(1);
const modalBody = ref(null);
const selectedItems = shallowRef([]);
const productCatalog = shallowRef([]);
const categories = shallowRef([]);
const brands = shallowRef([]);
const selectedCategory = shallowRef("");
const selectedBrand = shallowRef("");
const isLargeScreen = computed(() => !isMobile.value);
const visibleProducts = shallowRef([]);
const loadingTrigger = ref(null);
const observer = ref(null);

const currentBrand = computed(() =>
  findById(brands.value, selectedBrand.value)
);
const currentCategory = computed(() =>
  findById(categories.value, selectedCategory.value)
);
const getLastRowText = computed(() => {
  let message = "There is no more products to view";
  if (currentBrand.value) {
    message += ` in Brand: ${currentBrand.value.Name}`;
  }
  if (currentCategory.value) {
    message += ` in Category: ${currentCategory.value.Name}`;
  }
  if (searchTerm.value.length > 0) {
    message += ` and search keyword: ${searchTerm.value}`;
  }
  return message;
});

const toggleSortDirection = async () => {
  if (sortDirection.value == "none") {
    sortDirection.value = "asc";
    sortProductTitle.value = "Click to Sort Code Z-A";
  } else if (sortDirection.value == "asc") {
    sortDirection.value = "desc";
    sortProductTitle.value = "Click to Remove Sorting";
  } else {
    sortDirection.value = "none";
    sortProductTitle.value = "Click to Sort Code A-Z";
  }
  await loadProducts();
};

const getSelectedItem = (productId) => {
  return selectedItems.value.find((st) => st.product.Id == productId);
};

const handleProductUpdate = async () => {
  if (hasPOS.value) {
    selectedItems.value = await WorkingItemRepo.list(posCode.value);
  }
};

const updateEvent = async (type, value) => {
  if (type === "selectedBrand") {
    selectedBrand.value = value ?? "";
    selectedCategory.value = "";
    categories.value = await ProductRepo.getCategories(selectedBrand.value);
  } else if (type === "selectedCategory") {
    selectedCategory.value = value ?? "";
  } else if (type === "searchTerm") {
    searchTerm.value = value ?? "";
  }
};

const openModal = async () => {
  selectedItems.value = [];
  if (hasPOS.value) {
    selectedItems.value = await WorkingItemRepo.list(posCode.value);
  }
  showModal.value = true;
  if (initialized.value == false) {
    await loadProducts();
    categories.value = await ProductRepo.getCategories();
    brands.value = await ProductRepo.getBrands();
    initialized.value = true;
  }
  if (brands.value?.length == 0 || categories.value?.length == 0) {
    categories.value = await ProductRepo.getCategories();
    brands.value = await ProductRepo.getBrands();
  }

  if (document.body) {
    document.body.style.overflow = "hidden";
  }
};

const loadProducts = async (isLoadMore = false) => {
  if (isLoadMore) {
    if (noLoadMore.value) {
      dataIsLoading.value = false;
      return;
    }
    pageNumber.value++;
  } else {
    noLoadMore.value = false;
    pageNumber.value = 1;
    productCatalog.value = [];
  }
  dataIsLoading.value = true;

  const newProducts = await ProductRepo.list(
    selectedBrand.value,
    selectedCategory.value,
    searchTerm.value,
    sortDirection.value,
    pageNumber.value
  );

  if (!newProducts || newProducts.length === 0) {
    noLoadMore.value = true;
    dataIsLoading.value = false;
    return;
  }
  if (isLoadMore) {
    const uniqueNewProducts = newProducts.filter(
      (newProduct) =>
        !productCatalog.value.some(
          (existingProduct) => existingProduct.Id === newProduct.Id
        )
    );

    productCatalog.value.push(...uniqueNewProducts);
  } else {
    productCatalog.value = newProducts;
  }

  if (newProducts.length < 20) {
    noLoadMore.value = true;
  }

  dataIsLoading.value = false;
};

const closeModal = () => {
  showModal.value = false;
  if (document?.body) {
    document.body.style.overflow = "";
  }
};

const clearFilters = () => {
  sortDirection.value = "none";
  searchTerm.value = "";
  selectedBrand.value = "";
  selectedCategory.value = "";
  loadProducts();
};

const updateVisibleProducts = () => {
  visibleProducts.value = productCatalog.value;
};

const setupIntersectionObserver = () => {
  observer.value = new IntersectionObserver(
    (entries) => {
      if (
        entries[0].isIntersecting &&
        !dataIsLoading.value &&
        !noLoadMore.value
      ) {
        loadProducts(true);
      }
    },
    { threshold: 0.1 }
  );

  if (loadingTrigger.value) {
    observer.value.observe(loadingTrigger.value);
  }
};

onMounted(() => {
  setupIntersectionObserver();
  EventBus.$on("show-product-catalog-modal", openModal);
  EventBus.$on("order-placed", clearFilters);
});

onBeforeUnmount(() => {
  if (observer.value) {
    observer.value.disconnect();
  }
  EventBus.$off("order-placed", clearFilters);
  EventBus.$off("show-product-catalog-modal", openModal);
});

watch(productCatalog, updateVisibleProducts, { immediate: true });
watch([selectedBrand, selectedCategory, searchTerm, sortDirection], () => {
  loadProducts();
});
watch(
  () => posCode.value,
  (newValue, oldValue) => {
    if (oldValue && newValue !== oldValue) {
      clearFilters();
    }
  }
);
</script>

<style scoped>
.modal {
  --bs-modal-width: 95% !important;
}
.modal-content {
  height: 96% !important;
}
</style>
