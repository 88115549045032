import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "@/assets/app.scss";
import "@/utils/appLoader.js";
import "@/utils/pdfMake.js";
import EventBus from "@/services/eventBus.js";
import { createPinia } from "pinia";
import { initAppInsights } from "@/utils/errorHandler.js";
import Toast from "vue-toastification";
import { toastOptions } from "@/utils/toast-utils.js";
import clickOutside from "./directives/v-click-outside"; // Adjust the path

if (process.env.NODE_ENV === "production") {
  import("@/utils/appInsight.js");
}

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
initAppInsights(app);
app.config.performance = true;
app.provide("EventBus", EventBus);
app.use(Toast, toastOptions);
app.directive("click-outside", clickOutside);
app.mount("#app");
